var pdf = function () {
  this.vue = {}
  this.data = {}
  this.pageContents = 0
  this.row = 45
  this.create = function () {
    this.setTitle(20)
    this.bookingDetails(55)
    this.bookingUrl(115)
    this.adminPassword(125)
    this.helpDetails(200)
  }
  this.helpDetails = function (pos) {
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.setTextColor('#000000')
    this.doc.setDrawColor('#8bc34a')
    this.doc.line(0, (pos + 15), 210, (pos + 15), 'F')
    this.doc.line(0, (pos + 22), 210, (pos + 22), 'F')
    this.doc.setFontSize(10)
    this.doc.text(15, (pos + 20), '4. ' + this.vue.$tc('message.needHelp', 2))
    this.doc.setTextColor('#000000')
    this.doc.setFontSize(16)
    this.doc.text(15, (pos + 33), this.vue.$flix_url + '/' + 'help.html')
    this.doc.setFontSize(11)
    this.doc.setTextColor('#555')
    this.doc.text(15, (pos + 37 + 3), this.vue.$tc('message.calendar', 1) + ' #' + this.data.ID)
    this.doc.setTextColor('#000000')
  }
  this.bookingDetails = function (pos) {
    if (!this.data.data.password) {
      this.data.data.password = this.vue.$t('message.no')
    }
    if (this.data.data.comments.toString() === '0') {
      this.data.data.comments = this.vue.$t('message.yes')
    } else {
      this.data.data.comments = this.vue.$t('message.no')
    }
    if (this.data.data.exclude.anonym.toString() === '0') {
      this.data.data.exclude.anonym = this.vue.$t('message.no')
    } else {
      this.data.data.exclude.anonym = this.vue.$t('message.yes')
    }
    if (typeof this.data.data.location === 'undefined' || !this.data.data.location) {
      this.data.data.location = '---'
    }
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.setTextColor('#000000')
    this.doc.setDrawColor('#8bc34a')
    this.doc.line(0, (pos + 15), 210, (pos + 15), 'F')
    this.doc.line(0, (pos + 22), 210, (pos + 22), 'F')
    this.doc.setFontSize(10)
    this.doc.text(15, (pos + 20), '1. ' + this.vue.$tc('message.settings', 2) + ' ' + this.vue.$tc('message.calendar', 1))
    this.doc.setTextColor('#000000')
    this.doc.setFontSize(11)
    this.doc.text(15, (pos + 30), this.vue.$t('message.privateCalendar') + ': ' + this.vue.$t('message.yes'))
    this.doc.text(15, (pos + 36), this.vue.$t('message.title') + ': ' + this.data.data.title)
    this.doc.text(15, (pos + 42), this.vue.$t('message.password') + ': ' + this.data.data.password)
    this.doc.text(15, (pos + 48), this.vue.$t('message.maxApplicants') + ': ' + this.data.data.max_applicants)
    this.doc.text(15, (pos + 48 + 6), this.vue.$tc('message.comments', 2) + ': ' + this.data.data.comments)
    this.doc.text(15, (pos + 48 + 6 + 6), this.vue.$tc('message.location', 1) + ': ' + this.data.data.location)
    this.doc.text(15, (pos + 48 + 6 + 6 + 6), this.vue.$tc('message.anonymize', 2, { name: this.vue.$t('message.applicants') }) + ': ' + this.data.data.exclude.anonym)
    this.doc.setTextColor('#000000')
  }
  this.setTitle = function (pos) {
    this.doc.setFillColor('#eeeeee')
    this.doc.rect(0, 0, 210, 50, 'F')
    this.doc.setTextColor('#8bc34a')
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.text(15, (pos + 5), this.vue.$tc('message.overview', 2))
    this.doc.setTextColor('#000000')
    this.doc.setFontSize(14)
    this.doc.text(15, (pos + 13), this.data.data.title)
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.setFontSize(12)
    var begin = this.vue.$createDate(this.data.data.events[0].begin)
    var end = this.vue.$createDate(this.data.data.events[0].end)
    this.doc.text(15, (pos + 18), this.vue.$getNullsBefore(begin.getDate()) + '.' + this.vue.$getNullsBefore(begin.getMonth() + 1) + '.' + begin.getFullYear() + ', ' + this.vue.$getNullsBefore(begin.getHours()) + ':' + this.vue.$getNullsBefore(begin.getMinutes()) + ' ' + this.vue.$t('message.oClock') + ' - ' + this.vue.$getNullsBefore(end.getDate()) + '.' + this.vue.$getNullsBefore(end.getMonth() + 1) + '.' + end.getFullYear() + ', ' + this.vue.$getNullsBefore(end.getHours()) + ':' + this.vue.$getNullsBefore(end.getMinutes()) + ' ' + this.vue.$t('message.oClock'))
  }
  this.bookingUrl = function (pos) {
    this.doc.setDrawColor('#8bc34a')
    this.doc.line(0, (pos + 15), 210, (pos + 15), 'F')
    this.doc.line(0, (pos + 22), 210, (pos + 22), 'F')
    this.doc.setFontSize(10)
    this.doc.text(15, (pos + 20), '2. ' + this.vue.$t('message.share', { name: this.vue.$tc('message.pageBuilder', 1) }))
    this.doc.setTextColor('#d84315')
    this.doc.setFontSize(16)
    this.doc.text(15, (pos + 30), this.vue.$flix_url + '/pb/' + this.data.ID)
  }
  this.adminPassword = function (pos) {
    this.doc.setDrawColor('#8bc34a')
    this.doc.line(0, (pos + 40), 210, (pos + 40), 'F')
    this.doc.line(0, (pos + 47), 210, (pos + 47), 'F')
    this.doc.setDrawColor('#d84315')
    this.doc.setFillColor('#eeeeee')
    this.doc.rect(15, (pos + 55), 48, 18, 'F')
    this.doc.setTextColor('#000000')
    this.doc.setFontSize(10)
    this.doc.text(15, (pos + 45), '3. ' + this.vue.$tc('message.edit', 1, { name: this.vue.$tc('message.pageBuilder', 1) }))
    this.doc.setFontSize(10)
    this.doc.text(19, (pos + 60), this.vue.$tc('message.password', 1))
    this.doc.setTextColor('#d84315')
    this.doc.setFontSize(26)
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.text(19, (pos + 69), this.password)
    // this.doc.setFont('', '')
  }
}

export { pdf }

<template>
  <div>
    <!-- <iframe v-if="doc" :src="doc.output('dataurlstring')" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:90vw; height:100vh;" allowfullscreen></iframe> -->
  </div>
</template>
<script>
import jsPDF from 'jspdf'
import { pdf } from '@/components/pdf/pdfPrivate.js'
export default {
  components: {},
  props: {
    id: [Number, String]
  },
  data () {
    return {
      doc: false,
      data: {}
    }
  },
  methods: {
    getDoc () {
      var doc = new pdf()
      doc.doc = new jsPDF()
      doc.vue = this
      doc.data = this.data
      doc.password = this.$createPrivatePassword(this.data.ID)
      doc.create()
      this.doc = doc.doc
      this.doc.save(this.$tc('message.calendar', 1) + '-' + this.data.data.ID + '.pdf')
    },
    getData (callback) {
      this.$flix_post({
        url: 'assistent/privates/get',
        data: { ID: this.id },
        callback: function (ret) {
          if (ret.data[0]) {
            this.data = ret.data[1]
            callback()
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getData(function () {
      this.getDoc()
    }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
